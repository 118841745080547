import React from "react"
import { Text } from "@chakra-ui/react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { Link as Wrapper } from "@chakra-ui/react"


function About() {
  return (
    <Layout>
      <SEO title="About"
        keywords={['about', 'composer', 'musician', 'coder']} />
      <Text py={2}>
        Hi, I'm Antti. I am a composer, music technologist and an occasional web developer based in Helsinki, Finland.
      </Text>
      <Text py={2}> 
      My artistic work also includes live-coding music, improvising, sound art and instrument building and I am always looking for interesting ways to play around with sound and technology.
        Take a look at my <Wrapper><Link to="/portfolio" >portfolio</Link></Wrapper> to see what I've been up to!
      </Text>
      <Text py={2}>
      <Wrapper><Link to="/contact" >Contact me</Link></Wrapper> about commissions, scores, collabs, hate mail and anything in between!
      </Text>
    </Layout>
  )
}

export default About